<template>
    <div>
        <Doughnut
          :chart-options="chartOptions"
          :chart-data="chartData"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
    </div>
  </template>
  
  <script>
  import { Doughnut } from 'vue-chartjs/legacy'
  
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
  } from 'chart.js'
  
  ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)
  
  export default {
    name: 'DoughnutChart',
    components: {
      Doughnut
    },
    props: {
        dataPaidUnpaid: null,
        currencyName: null,
      chartId: {
        type: String,
        default: 'doughnut-chart'
      },
      datasetIdKey: {
        type: String,
        default: 'label'
      },
      width: {
        type: Number,
        default: 400
      },
      height: {
        type: Number,
        default: 400
      },
      cssClasses: {
        default: '',
        type: String
      },
      styles: {
        type: Object,
        default: () => {}
      },
      plugins: {
        type: Array,
        default: () => []
      }
    },
      watch:{
          dataPaidUnpaid: function (val) {
              this.chartData.labels = val.labels;
              this.chartData.datasets[0].data = val.values;
          }
      },
    data() {
      return {
        chartData: {
          labels: [],
          datasets: [
            {
              backgroundColor: ['#41B883', '#E46651'],
              data: []
            }
          ]
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false
        }
      }
    },
    mounted() {
        this.chartData.labels = this.dataPaidUnpaid.labels;
        this.chartData.datasets[0].data = this.dataPaidUnpaid.values;
    }
  }
  </script>
  