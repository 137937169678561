import jsPdfExport from "@/core/config/jsPdfExport";
let exportPDFInnerAction = (_id , type, fileName, title = null, selectorExport = 'selectorExport', looping = false) => {
    let element = document.getElementById(_id);
    let elementSelectorHeader = document.getElementById('selectorHeader');

    let clone = element.cloneNode(true);

    let newEle = document.createElement("div");
    newEle.style.width = "210mm";
    newEle.style.fontFamily = "Amiri";
    newEle.style.margin = "15px -45px";
    newEle.style.float = "right";
    newEle.style.direction = "rtl";

    newEle.id = "cloneEle";
    if (elementSelectorHeader){
        newEle.appendChild(elementSelectorHeader);
    }
    if (looping){
        NodeList.prototype.forEach = Array.prototype.forEach
        let children = clone.childNodes;
        children.forEach(function(item){
            console.log(item);
            newEle.append(item);
        });

        // let children = clone.childNodes;
        // for(let child in children){
        //     console.log(children);
        //     newEle.append(children[child].innerHTML);
        // }
    }else {
        newEle.appendChild(clone);
    }




    // clone.style.width = "235mm";
    // clone.style.fontFamily = "Amiri";
    // clone.style.margin = "15px 15px";
    // clone.id = "cloneEle";
    document.getElementById(selectorExport).innerHTML = '';
    document.getElementById(selectorExport).appendChild(newEle) ;
    let clone_element = document.getElementById("cloneEle");

    let pdf = jsPdfExport("p", "px", 'a4');
    if (title){
        pdf.text(title, 20, 20);
    }
    pdf.html(clone_element, {
        html2canvas: {
            scale: 0.5,
        },
        callback: function (pdf) {
            if (type == 'pdf') {
                pdf.save(fileName);
            } else {
                pdf.autoPrint();
                pdf.output('dataurlnewwindow');
            }
        },

    });
}
export default exportPDFInnerAction;