<template>
  <div>
    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="supplier_id">{{ $t('purchase_report.supplier') }}</label>
                <multiselect id="supplier_id"
                             v-model="supplier"
                             :placeholder="$t('purchase_report.supplier')"
                             label="full_name"
                             track-by="id"
                             :options="suppliers"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getSuppliers($event)">
                </multiselect>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('purchase_report.from_date') }}</label>
                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('purchase_report.to_date') }}</label>
                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('purchase_report.added_by') }}</label>
                <multiselect id="user_id"
                             v-model="user"
                             :placeholder="$t('purchase_report.added_by')"
                             label="name"
                             track-by="id"
                             :options="users"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getUsers($event)">
                </multiselect>
              </div>


              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="f_status">{{ $t('status') }}</label>
                <select name="" id="f_status" v-model="is_paid" type="text" class="custom-select">
                  <option value="">{{ $t('all') }}</option>
                  <option v-for="(row, index) in status_list" :value="row.id" :key="'status'+index">{{ row.title }}</option>
                </select>
              </div>


              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                  {{ $t('display_report') }}
                </button>
                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <!-- End Filter -->
    <div class="card card-custom">
      <div class="card-body">
        <b-tabs content-class="mt-3">
          <b-tab :title="$t('summery')" :active="tab == 'summery' || tab == null">
            <div class="row mb-10">
              <div class="col-md-12 mt-10" id="summaryTableTitle">
                <h4 class="text-center">{{ $t('purchase_report.purchase_report') }}</h4>
                <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from') }} {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
              </div>
              <div class="col-md-6 mt-10" v-if="line_invoice_details">
                <LineChart :data-line-invoice="line_invoice_details" :currency-name="currency_name"/>
              </div>
              <div class="col-md-6 mt-10" v-if="paid_unpaid_details">
                <DoughnutChart :data-paid-unpaid="paid_unpaid_details" :currency-name="currency_name"/>
              </div>
            </div>
            <div class="row mb-10">
              <div class="col-12" v-if="data_report_details">
                <div class="table-responsive">
                  <div class="row justify-content-end p-4">
                    <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                      <download-excel
                          class="dropdown-item"
                          :fetch="salesInvoiceExport"
                          :fields="json_fields"
                          :name="$t('MENU.purchases_invoices_segmented_Reports')+'.xls'">
                        <i class="la la-file-excel"></i>{{ $t('excel') }}
                      </download-excel>
                      <button class="dropdown-item" @click="printData('summaryTable', 'print')">
                        <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                      </button>
                      <button class="dropdown-item" @click="printData('summaryTable','pdf')">
                        <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                      </button>
                    </b-dropdown>
                  </div>
                  <table class="table table-bordered" id="summaryTable">
                    <thead>
                    <tr>
                      <th v-if="isPeriod">{{ $t('purchase_report.' + periodLabel) }}</th>
                      <th v-else>{{ $t('purchase_report.supplier') }}</th>
                      <!--                                            <template v-if="!isPeriod">-->
                      <th>{{ $t('purchase_report.paid') }} ({{ currency_name }})</th>
                      <th>{{ $t('purchase_report.unpaid') }} ({{ currency_name }})</th>
                      <!--                                            </template>-->
                      <th>{{ $t('purchase_report.total') }} ({{ currency_name }})</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row2, index2) in data_report_summary" :key="'a'+getRandom()+index2">
                      <td>{{ row2.label }}</td>
                      <!--                                            <template v-if="!isPeriod">-->
                      <td>{{ row2.paid }}</td>
                      <td>{{ row2.unpaid }}</td>
                      <!--                                            </template>-->
                      <td>{{ row2.total }}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                      <td><b>{{ $t('purchase_report.total') }}</b></td>
                      <!--                                            <template v-if="!isPeriod">-->
                      <th>{{ summary_total_sum.sum_paid ? summary_total_sum.sum_paid : 0 }}</th>
                      <th>{{ summary_total_sum.sum_unpaid ? summary_total_sum.sum_unpaid : 0 }}</th>
                      <!--                                            </template>-->
                      <th>{{ summary_total_sum.sum_total ? summary_total_sum.sum_total : 0 }}</th>
                    </tr>
                    </tfoot>
                  </table>
                </div>

              </div>
            </div>
          </b-tab>

          <b-tab :title="$t('details')" :active="tab == 'details'">
            <div class="mt-3">
              <div class="row mb-10">
                <div class="col-md-12 mt-10" id="summaryTableDetailsTitle">
                  <h4 class="text-center">{{ $t('purchase_report.purchase_report') }}</h4>
                  <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from') }} {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
                </div>
                <div class="col-md-6 mt-10" v-if="line_invoice_details">
                  <LineChart :data-line-invoice="line_invoice_details" :currency-name="currency_name"/>
                </div>
                <div class="col-md-6 mt-10" v-if="paid_unpaid_details">
                  <DoughnutChart :data-paid-unpaid="paid_unpaid_details" :currency-name="currency_name"/>
                </div>
              </div>
              <div class="row mb-10">
                <div class="col-12" v-if="data_report_details">
                  <div class="table-responsive">
                    <div class="row justify-content-end p-4">
                      <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                        <download-excel
                            class="dropdown-item"
                            :fetch="salesInvoiceDetailsExport"
                            :fields="json_fields_details"
                            :name="$t('MENU.purchases_invoices_segmented_Reports')+'.xls'">
                          <i class="la la-file-excel"></i>{{ $t('excel') }}
                        </download-excel>
                        <button class="dropdown-item" @click="printData('summaryTableDetails', 'print')">
                          <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                        </button>
                        <button class="dropdown-item" @click="printData('summaryTableDetails', 'pdf')">
                          <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                        </button>
                      </b-dropdown>
                    </div>
                    <table class="table table-bordered" id="summaryTableDetails">
                      <thead>
                      <tr>
                        <th>{{ $t('purchase_report.number') }}</th>
                        <th>{{ $t('purchase_report.supplier') }}</th>
                        <th>{{ $t('purchase_report.date') }}</th>
                        <th>{{ $t('purchase_report.employee') }}</th>
                        <th>{{ $t('purchase_report.paid') }} ({{ currency_name }})</th>
                        <th>{{ $t('purchase_report.unpaid') }} ({{ currency_name }})</th>
                        <th>{{ $t('purchase_report.total') }} ({{ currency_name }})</th>
                      </tr>
                      </thead>
                      <tbody>
                      <template v-for="(row, index) in data_report_details">
                        <tr :key="'a'+index">
                          <td colspan="7">{{ row.label }}</td>
                        </tr>
                        <tr v-for="(row2, index2) in row.details" :key="'a'+getRandom()+index2">
                          <td>{{ row2.id }}</td>
                          <td>{{ row2.supplier_name }}</td>
                          <td>{{ row2.date }}</td>
                          <td>{{ row2.added_by }}</td>
                          <td>{{ row2.paid }}</td>
                          <td>{{ row2.unpaid }}</td>
                          <td>{{ row2.total }}</td>
                        </tr>
                        <tr :key="'b'+index">
                          <td colspan="4">{{ $t('purchase_report.total') }}</td>
                          <td>{{ row.total.sum_paid }}</td>
                          <td>{{ row.total.sum_unpaid }}</td>
                          <td>{{ row.total.sum_total }}</td>
                        </tr>
                      </template>

                      </tbody>
                      <tfoot>
                      <tr>
                        <td colspan="4"><b>{{ $t('purchase_report.total') }}</b></td>
                        <th>{{ details_total_sum.sum_paid_total ? details_total_sum.sum_paid_total : 0 }}</th>
                        <th>{{ details_total_sum.sum_unpaid_total ? details_total_sum.sum_unpaid_total : 0 }}</th>
                        <th>{{ details_total_sum.sum_total_total ? details_total_sum.sum_total_total : 0 }}</th>
                      </tr>
                      </tfoot>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </b-tab>
          <template #tabs-end>
            <li class="nav-item  d-flex my-auto">
              <select name="" id="" v-model="filters.filter_by" class="custom-select border-0 pt-0 pb-0 h-auto shadow-none">
                <option :value="null">{{ $t('select_filter') }}</option>
                <option value="daily">{{ $t('daily') }}</option>
                <option value="weekly">{{ $t('weekly') }}</option>
                <option value="monthly">{{ $t('monthly') }}</option>
                <option value="yearly">{{ $t('yearly') }}</option>
                <option value="supplier">{{ $t('supplier') }}</option>
                <option value="employee">{{ $t('employee') }}</option>
              </select>

            </li>
          </template>
          <!-- <b-tab> -->
          <!-- </b-tab> -->
        </b-tabs>

        <div class="row">
          <div class="col-12 mt-5 text-center">
            <button class="btn btn-warning" @click="loadMore">
              <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
              {{ $t('load_more') }}
            </button>
          </div>
        </div>


      </div>
    </div>
    <div class="selector-export" id="selectorExport"></div>
    <!--end::supplier-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import DoughnutChart from './components/Doughnut.vue';
import LineChart from './components/Line.vue';
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";

export default {
  name: "index-purchase-by-supplier-report",
  components: {DoughnutChart, LineChart},
  data() {
    return {
      mainRoute: '/reports/purchases/supplier',
      mainRouteDependency: 'base/dependency',

      currency_name: null,
      supplier: null,

      line_invoice_details: [],
      paid_unpaid_details: [],

      data_report_details: [],
      details_total_sum: [],

      data_report_summary: [],
      summary_total_sum: [],


      filters: {
        supplier_id: null,
        from_date: null,
        to_date: null,
        user_id: null,
        overdue: null,
        f_status: null,
        filter_by: null,
      },
      is_paid: null,
      status_list: [
        {id: 1, title: this.$t('paid')},
        {id: 2, title: this.$t('partial_paid')},
        {id: 3, title: this.$t('unpaid')},
        // {id: 4, title: this.$t('refund')},
        {id: 5, title: this.$t('overdue')},
      ],

      data: [],
      suppliers: [],
      users: [],
      user: null,
      employees: [],

      isPeriod: false,
      periodLabel: '',
      tab: this.$route.query.tab ? this.$route.query.tab : null,
      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 0,
    }
  },
  watch: {
    user: function (val) {
      if (val) {
        this.filters.user_id = val.id;
      } else {
        this.filters.user_id = null;
      }
    },
    "filters.filter_by": function (val) {
      if (val)
        this.doFilter();
      switch (val) {
        case 'daily':
          this.isPeriod = true;
          this.periodLabel = 'day';
          break;
        case 'weekly':
          this.isPeriod = true;
          this.periodLabel = 'week';
          break;
        case 'monthly':
          this.isPeriod = true;
          this.periodLabel = 'month';
          break;
        case 'yearly':
          this.isPeriod = true;
          this.periodLabel = 'year';
          break;
        case 'supplier':
          this.isPeriod = true;
          this.periodLabel = 'supplier';
          break;
        case 'employee':
          this.isPeriod = true;
          this.periodLabel = 'employee';
          break;
        default:
          this.isPeriod = false;
          this.periodLabel = '';
          break;
      }
    },
    supplier: function (val) {
      if (val) {
        this.filters.supplier_id = val.id;
      } else {
        this.filters.supplier_id = null;
      }
    },
    is_paid: function (val) {
      if (val == 5) {
        this.filters.f_status = null;
        this.filters.overdue = 1;
      } else {
        this.filters.f_status = val;
        this.filters.overdue = null;
      }
    },
  },
  computed: {

    json_fields: function () {
      let fields = {};

      if (this.isPeriod) {
        fields[this.$t('purchase_report.' + this.periodLabel)] = 'label';
      } else {
        fields[this.$t('purchase_report.supplier')] = 'label';
        fields[this.$t('purchase_report.paid')] = 'paid';
        fields[this.$t('purchase_report.unpaid')] = 'unpaid';
      }

      fields[this.$t('purchase_report.total') + ` (${this.currency_name})`] = 'total';

      return fields;
    },

    json_fields_details: function () {
      let fields = {};

      fields[this.$t('purchase_report.number')] = 'id';
      fields[this.$t('purchase_report.supplier')] = 'supplier_name';
      fields[this.$t('purchase_report.date')] = 'date';
      fields[this.$t('purchase_report.added_by')] = 'added_by';
      fields[this.$t('purchase_report.paid')] = 'paid';
      fields[this.$t('purchase_report.unpaid')] = 'unpaid';
      fields[this.$t('purchase_report.total') + ` (${this.currency_name})`] = 'total';

      return fields;
    },


  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.purchases_reports'), route: '/reports/purchase-reports-links'}, {title: this.$t("MENU.purchases_invoices_segmented_Reports")}]);
    this.filters.filter_by = this.$route.query.filter ? this.$route.query.filter : null;

    // this.getEmployees();
    // this.getStatusList();
    this.getAuthUser();
  },
  methods: {

    salesInvoiceExport() {
      this.setReportLog('excel', 'purchases invoices segmented reports');
      let _footer = {
        label: this.$t('purchase_report.total') + ` (${this.currency_name})`,
        paid: this.summary_total_sum.sum_paid,
        unpaid: this.summary_total_sum.sum_unpaid,
        total: this.summary_total_sum.sum_total,
      };
      let _data = [];
      this.data_report_summary.forEach((row) => {
        _data.push({
          label: row.label,
          paid: row.paid,
          unpaid: row.unpaid,
          total: row.total,
        });
      });
      _data.push(_footer);

      return _data;
    },
    salesInvoiceDetailsExport() {
      let _footer = {
        id: this.$t('purchase_report.total') + ` (${this.currency_name})`,
        supplier_name: '',
        date: '',
        added_by: '',
        paid: this.details_total_sum.sum_paid_total,
        unpaid: this.details_total_sum.sum_unpaid_total,
        total: this.details_total_sum.sum_total_total,
      };
      let _data = [];
      this.data_report_details.forEach((row) => {
        _data.push({
          id: row.label,
          supplier_name: '',
          date: '',
          added_by: '',
          paid: '',
          unpaid: '',
          total: '',
        });
        row.details.forEach((sub_row) => {
          _data.push({
            id: sub_row.id,
            supplier_name: sub_row.label,
            date: sub_row.date,
            added_by: sub_row.added_by,
            paid: sub_row.paid,
            unpaid: sub_row.unpaid,
            total: sub_row.total,
          });
        });
        _data.push({
          id: this.$t('purchase_report.total') + ` (${this.currency_name})`,
          supplier_name: '',
          date: '',
          added_by: '',
          paid: '',
          unpaid: '',
          total: row.total.sum_total,
        });
      });
      _data.push(_footer);

      return _data;
    },

    getRandom() {
      return Math.floor(Math.random() * 10000);
    },

    // fnExcelReport() {
    //     let table = document.getElementById('revenue_invoice');
    //     TableToExcel.convert(table, { // html code may contain multiple tables so here we are refering to 1st table tag
    //         name: this.$t('purchase_report')+'.xlsx', // fileName you could use any name
    //         sheet: {
    //             name: 'Sheet 1' // sheetName
    //         }
    //     });
    // },
    doFilter() {
      this.getLineChartInvoiceDetails();
      this.getPaidUnpaidInvoiceDetails();
      this.getReportInvoiceDetails();
      this.getReportInvoiceSummary();

    },
    resetFilter() {
      this.supplier = null;
      this.user = null;

      this.filters.supplier_id = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.user_id = null;
      this.is_paid = null;
      this.filters.f_status = null;
      this.filters.overdue = null;
    },

    // getStatusList() {
    //     ApiService.get(this.mainRouteDependency + "/status").then((response) => {
    //         this.status_list = response.data.data;
    //     });
    // },

    async getSuppliers(filter) {
      if (filter && filter.length >= 3)
        await ApiService.get(this.mainRouteDependency + "/suppliers", {params: {filter: filter}}).then((response) => {
          this.suppliers = response.data.data;
        });
    },
    getUsers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/users", {params: {filter: filter}}).then((response) => {
          this.users = response.data.data;
        });
    },
    getAuthUser() {
      ApiService.get(`/get_auth`).then((response) => {
        this.currency_name = response.data.data.currency_name;
      });
    },
    getEmployees() {
      ApiService.get(this.mainRouteDependency + "/employees").then((response) => {
        this.employees = response.data.data;
      });
    },

    loadMore() {
      this.page = this.page + 1;

      this.getLineChartInvoiceDetails();
      this.getPaidUnpaidInvoiceDetails();
      this.getReportInvoiceDetails();
      this.getReportInvoiceSummary();
    },

    /*****
     * details
     */
    getLineChartInvoiceDetails() {
      ApiService.get(`${this.mainRoute}/details_chart`, {params: {...this.filters, page: (this.page ? this.page : 1), type: 'bar'}}).then((response) => {
        this.line_invoice_details = response.data;
      });
    },
    getPaidUnpaidInvoiceDetails() {
      ApiService.get(`${this.mainRoute}/details_chart`, {params: {...this.filters, page: (this.page ? this.page : 1), type: 'donut'}}).then((response) => {
        this.paid_unpaid_details = response.data.data;
      });
    },
    getReportInvoiceDetails() {
      ApiService.get(`${this.mainRoute}/details`, {params: {...this.filters, page: (this.page ? this.page : 1)}}).then((response) => {
        this.data_report_details = response.data.data.data;
        this.details_total_sum = response.data.data.total;
      });
    },
    /**
     * get for summary tab
     */
    getReportInvoiceSummary() {
      ApiService.get(`${this.mainRoute}/details`, {params: {...this.filters, page: (this.page ? this.page : 1), data_type: 'list'}}).then((response) => {
        this.data_report_summary = response.data.data;
        this.summary_total_sum = response.data.total;
      });
    },
    printData(tableId, type) {
      this.setReportLog('pdf', 'purchases invoices segmented reports');

      // let divToPrint = document.getElementById(tableId);
      // let divTitleToPrint = document.getElementById(tableId + 'Title');
      // let newWin = window.open("");
      // newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
      // setTimeout(() => {
      //     newWin.print();
      //     newWin.close();
      // }, 100)
      exportPDFInnerAction(tableId, type, this.$t('MENU.purchases_invoices_segmented_Reports'), this.$t('MENU.purchases_invoices_segmented_Reports'));

    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }

  },
};
</script>
<style>
.html2pdf__overlay {
  opacity: 0 !important;
  z-index: -1000;

}

.selector-export {
  opacity: 0 !important;
  z-index: -1000 !important;
  width: 0.0001px !important;
  height: 0.0001px !important;
  overflow: auto;
}

</style>